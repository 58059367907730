import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "git"
    }}>{`Git`}</h1>
    <h2 {...{
      "id": "clonado-de-repos-con-access-token"
    }}>{`Clonado de repos con access token`}</h2>
    <p>{`Básicamente, si queremos bajar un repo, como si fuese de github, en gitlab no basta solo nuestra autentificación, precisamos de un access token. `}</p>
    <p>{`dentro de la consola, vamos a hacer lo mismo de siempre para clonar un REPO.`}</p>
    <p>{`ejemplo:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
git clone https://gitlab.com/ecomerciar-projects/manuales-y-procesos

`}</code></pre>
    <p>{`Pero esto va dar un error de autentificación, tenemos que clavarle el access token al url.`}<br parentName="p"></br>{`
`}{`Para generar un access token, desde gitlab tenemos esto: `}</p>
    <ol>
      <li parentName="ol">{`Log in to GitLab.`}</li>
      <li parentName="ol">{`In the upper-right corner, click your avatar and select Settings.`}</li>
      <li parentName="ol">{`On the User Settings menu, select Access Tokens.`}</li>
      <li parentName="ol">{`Choose a name and optional expiry date for the token.`}</li>
      <li parentName="ol">{`Choose the desired scopes.`}</li>
      <li parentName="ol">{`Click the Create personal access token button.`}</li>
      <li parentName="ol">{`Save the personal access token somewhere safe. Once you leave or refresh the page, you won’t be able to access it again.`}</li>
    </ol>
    <p>{`Esto nos va a dar un token como este: YfrNshasadZ1DFsvo5FKj`}<br parentName="p"></br>{`
`}{`Hay que guardarlo en algun lugar donde no se pierda, ej: bitwarden.`}</p>
    <p>{`Ahora, desde tu consola, o terminal, vamos a clonar el repo con el access token en el medio:`}</p>
    <p>{`asi: `}<a parentName="p" {...{
        "href": "https://oauth2:ACCESS_TOKEN@somegitlab.com/vendor/package.git"
      }}>{`https://oauth2:ACCESS_TOKEN@somegitlab.com/vendor/package.git`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
git clone https://oauth2:YfrNshasadZ1DFsvo5FKj@gitlab.com/ecomerciar-projects/manuales-y-procesos

`}</code></pre>
    <p>{`Esto ya nos debería clonar el repo con el git incluido, lo cual nos va a permitir hacer pull, push, etc.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      